import { Link } from "react-router-dom";
import NationalForm from "../components/NationalForm";

export default function Registration() {
  return (
    <div>
      <Link to="/">
        <button className="p-2 mx-6 bg-orange-300 rounded-md text-white hover:bg-orange-400">
          Back
        </button>
      </Link>
      <h1 className="text-5xl font-bold text-center mx-auto p-5 max-md:text-5xl max-sm:text-2xl">
        2024 Canada National Super<br></br>Spelling Bee Registration
      </h1>
      <NationalForm></NationalForm>
    </div>
  );
}
