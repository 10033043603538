import { useEffect, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { Link } from "react-router-dom";

export default function CompletedCampForm() {
  const { user, dispatch } = useAuthContext();
  const userEmail = user.username;

  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState([]);
  const [summerCamp, setSummerCamp] = useState(false);
  const [numTrays, setNumTrays] = useState(0);

  const [SAdult, setSAdult] = useState(0);
  const [MChild, setMChild] = useState(0);
  const [LChild, setLChild] = useState(0);
  const [XLChild, setXLChild] = useState(0);
  const [XXLChild, setXXLChild] = useState(0);
  const [XXXLChild, setXXXLChild] = useState(0);
  const [MAdult, setMAdult] = useState(0);
  const [LAdult, setLAdult] = useState(0);
  const [XLAdult, setXLAdult] = useState(0);
  const [XXLAdult, setXXLAdult] = useState(0);
  const [XXXLAdult, setXXXLAdult] = useState(0);
  const [XXXXLAdult, setXXXXLAdult] = useState(0);
  const [XXXXXLAdult, setXXXXXLAdult] = useState(0);

  const [parentSignature, setParentSignature] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    let json;
    let response;
    const fetchUserInformation = async () => {
      response = await fetch(
        `https://forms-api.canadaspellingbee.com/api/user/get${userEmail}`
      );
      json = await response.json();
      if (!response.ok) {
        return;
      }

      const extra = json?.user?.extra;

      if (extra) {
        setNumTrays(extra.numTrays);
        setSAdult(extra.shirts.SAdult);
        setMChild(extra.shirts.MChild);
        setLChild(extra.shirts.LChild);
        setXLChild(extra.shirts.XLChild);
        setXXLChild(extra.shirts.XXLChild);
        setXXXLChild(extra.shirts.XXXLChild);
        setMAdult(extra.shirts.MAdult);
        setLAdult(extra.shirts.LAdult);
        setXLAdult(extra.shirts.XLAdult);
        setXXLAdult(extra.shirts.XXLAdult);
        setXXXLAdult(extra.shirts.XXXLAdult);
        setXXXXLAdult(extra.shirts.XXXXLAdult);
        setXXXXXLAdult(extra.shirts.XXXXXLAdult);
        setParentSignature(extra.signature);
        setDate(extra.date);
        setTotal(extra.total);
        setOrder(extra.order);
        setSummerCamp(extra.dateSelection === "yes" ? true : false);
      }
    };

    fetchUserInformation();
  }, []);

  return (
    <div>
      <Link to="/">
        <button className="p-2 mx-6 bg-orange-300 rounded-md text-white hover:bg-orange-400">
          Back
        </button>
      </Link>

      <main className="flex flex-col justify-center items-center">
        <div className="p-6 border-2 border-dashed border-red-400 w-[60%] max-md:w-[90%] max-lg:w-[75%] m-1">
          <div className="flex flex-col justify-center items-start">
            <div className="mx-auto">
              {user?.payment ? null : (
                <Link to="/payment">
                  <button className="p-2 mx-6 bg-orange-300 rounded-md text-white hover:bg-orange-400">
                    Pay here
                  </button>
                </Link>
              )}
            </div>
            <div className="m-auto text-2xl p-3 text-green-900 font-bold text-center">
              You have completed this form!<br></br>This is what we received:
            </div>
            <h1 className="text-xl font-semibold">
              Order Lunch Trays for the National Bee
            </h1>
            <h1 className="text-sm">$20 CAD per meal, HST included</h1>
            <div>
              <ul>
                {order.map((order, index) => (
                  <div
                    key={index}
                    className=" border-slate-500 border-2 p-2 m-1"
                  >
                    <div className="font-semibold pt-4">Order {index + 1}</div>
                    <div>Main Selection: {order.mainSelection}</div>
                    <div>Side 1 Selection: {order.side1Selection}</div>
                    <div>Side 2 Selection: {order.side2Selection}</div>
                    <div className="pb-4">
                      Drink Selection: {order.drinkSelection}
                    </div>
                  </div>
                ))}
              </ul>
            </div>
            <div className="flex gap-x-7 flex-col pb-12">
              <div>Total Meals in Cart: {numTrays}</div>
              <div>
                Total Cost of Meals: <b>${numTrays * 20} CAD</b>
              </div>
            </div>
            <h1 className="text-xl font-semibold">Order CSSB Shirts</h1>
            <h1 className="text-sm">
              First shirt is free for the speller, every other added shirt is
              $25 CAD. Will be available for pick up at the National Bee.
            </h1>
            <h1 className="text-sm">Number of Shirts:</h1>
            <div className="pb-12">
              <div className="flex gap-x-7 flex-wrap">
                <div className="flex flex-col w-[10%] items-center">
                  <div
                    name="num-SAdult"
                    className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-slate-400 rounded-md w-full text-slate-600"
                  >
                    {SAdult}
                  </div>
                  <label
                    htmlFor="num-SAdult"
                    className="pb-[1vw] text-xs w-fit"
                  >
                    S (Adult)
                  </label>
                </div>
                <div className="flex flex-col w-[10%] items-center">
                  <div
                    name="num-MChild"
                    className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-slate-400 rounded-md w-full text-slate-600"
                  >
                    {MChild}
                  </div>
                  <label
                    htmlFor="num-MChild"
                    className="pb-[1vw] text-xs w-fit"
                  >
                    M (Child)
                  </label>
                </div>
                <div className="flex flex-col w-[10%] items-center">
                  <div
                    name="num-MAdult"
                    className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-slate-400 rounded-md w-full text-slate-600"
                  >
                    {MAdult}
                  </div>
                  <label
                    htmlFor="num-MAdult"
                    className="pb-[1vw] text-xs w-fit"
                  >
                    M (Adult)
                  </label>
                </div>
                <div className="flex flex-col w-[10%] items-center">
                  <div
                    name="num-LChild"
                    className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-slate-400 rounded-md w-full text-slate-600"
                  >
                    {LChild}
                  </div>
                  <label
                    htmlFor="num-LChild"
                    className="pb-[1vw] text-xs w-fit"
                  >
                    L (Child)
                  </label>
                </div>
                <div className="flex flex-col w-[10%] items-center">
                  <div
                    name="num-LAdult"
                    className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-slate-400 rounded-md w-full text-slate-600"
                  >
                    {LAdult}
                  </div>
                  <label
                    htmlFor="num-LAdult"
                    className="pb-[1vw] text-xs w-fit"
                  >
                    L (Adult)
                  </label>
                </div>
                <div className="flex flex-col w-[10%] items-center">
                  <div
                    name="num-XLChild"
                    className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-slate-400 rounded-md w-full text-slate-600"
                  >
                    {XLChild}
                  </div>
                  <label
                    htmlFor="num-XLChild"
                    className="pb-[1vw] text-xs w-fit"
                  >
                    XL (Child)
                  </label>
                </div>
                <div className="flex flex-col w-[10%] items-center">
                  <div
                    name="num-XLAdult"
                    className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-slate-400 rounded-md w-full text-slate-600"
                  >
                    {XLAdult}
                  </div>
                  <label
                    htmlFor="num-XLAdult"
                    className="pb-[1vw] text-xs w-fit"
                  >
                    XL (Adult)
                  </label>
                </div>
                <div className="flex flex-col w-[10%] items-center">
                  <div
                    name="num-XXLChild"
                    className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-slate-400 rounded-md w-full text-slate-600"
                  >
                    {XXLChild}
                  </div>
                  <label
                    htmlFor="num-XXLChild"
                    className="pb-[1vw] text-xs w-fit"
                  >
                    XXL (Child)
                  </label>
                </div>
                <div className="flex flex-col w-[10%] items-center">
                  <div
                    name="num-XXLAdult"
                    className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-slate-400 rounded-md w-full text-slate-600"
                  >
                    {XXLAdult}
                  </div>
                  <label
                    htmlFor="num-XXLAdult"
                    className="pb-[1vw] text-xs w-fit"
                  >
                    XXL (Adult)
                  </label>
                </div>
                <div className="flex flex-col w-[10%] items-center">
                  <div
                    name="num-XXXLChild"
                    className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-slate-400 rounded-md w-full text-slate-600"
                  >
                    {XXXLChild}
                  </div>
                  <label
                    htmlFor="num-XXXLChild"
                    className="pb-[1vw] text-xs w-fit"
                  >
                    XXXL (Child)
                  </label>
                </div>
                <div className="flex flex-col w-[10%] items-center">
                  <div
                    name="num-XXXLAdult"
                    className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-slate-400 rounded-md w-full text-slate-600"
                  >
                    {XXXLAdult}
                  </div>
                  <label
                    htmlFor="num-XXXLAdult"
                    className="pb-[1vw] text-xs w-fit"
                  >
                    XXXL (Adult)
                  </label>
                </div>
                <div className="flex flex-col w-[10%] items-center">
                  <div
                    name="num-XXXXLAdult"
                    className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-slate-400 rounded-md w-full text-slate-600"
                  >
                    {XXXXLAdult}
                  </div>
                  <label
                    htmlFor="num-XXXXLAdult"
                    className="pb-[1vw] text-xs w-fit"
                  >
                    XXXXL (Adult)
                  </label>
                </div>
                <div className="flex flex-col w-[10%] items-center">
                  <div
                    name="num-XXXXXLAdult"
                    className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-slate-400 rounded-md w-full text-slate-600"
                  >
                    {XXXXXLAdult}
                  </div>
                  <label
                    htmlFor="num-XXXXXLAdult"
                    className="pb-[1vw] text-xs w-fit"
                  >
                    XXXXXL (Adult)
                  </label>
                </div>
              </div>
              <a
                href="http://canadaspellingbee.com/speller"
                target="_blank"
                className="text-blue-500 hover:underline"
              >
                Click for Information (Including Sizing Chart)
              </a>

              <div>
                Total Shirts in Cart:{" "}
                {XXXXXLAdult +
                  XXXXLAdult +
                  XXXLChild +
                  XXLChild +
                  XLChild +
                  LChild +
                  MChild +
                  XXXLAdult +
                  XXLAdult +
                  XLAdult +
                  LAdult +
                  MAdult +
                  SAdult}
              </div>
              <div>
                Total Cost of Shirts (One Free Shirt):{" "}
                <b>
                  $
                  {XXXXXLAdult +
                    XXXXLAdult +
                    XXXLChild +
                    XXLChild +
                    XLChild +
                    LChild +
                    MChild +
                    XXXLAdult +
                    XXLAdult +
                    XLAdult +
                    LAdult +
                    MAdult +
                    SAdult >
                  0
                    ? 25 *
                      (XXXXXLAdult +
                        XXXXLAdult +
                        XXXLChild +
                        XXLChild +
                        XLChild +
                        LChild +
                        MChild +
                        XXXLAdult +
                        XXLAdult +
                        XLAdult +
                        LAdult +
                        MAdult +
                        SAdult -
                        1)
                    : 0}{" "}
                  CAD
                </b>
              </div>
            </div>

            <h1 className="text-xl font-semibold">Summer Camp Registration</h1>
            <div className="flex gap-4 flex-wrap pb-6">
              <div className="flex items-start gap-1 pb-[1vw] flex-col">
                <label htmlFor="date-selection" className="text-sm">
                  Would you like to register for the 2024 CSSB Super Camp? (July
                  17th - July 19th, 2024)
                </label>
                <div className="py-4 text-green-900">
                  {summerCamp ? (
                    <div className="font-semibold">
                      Yes, I would like to register for the 2024 CSSB Super Camp
                    </div>
                  ) : (
                    <div className="font-semibold">
                      No, I would not like to register for the 2024 CSSB Super
                      Camp
                    </div>
                  )}
                </div>
                <div>
                  Early Bird Fee: $299 CAD (until May 12 at 11:59PM EST)
                </div>
                <div>Regular Fee: $399 CAD</div>
                <a
                  href="http://canadaspellingbee.com/speller"
                  target="_blank"
                  className="text-blue-500 hover:underline"
                >
                  Click for More Information
                </a>
              </div>
            </div>
            <div>
              Total Cost of Summer Camp: <b>${summerCamp ? 299 : 0} CAD</b>
            </div>
            <div className="mx-auto">
              <div className="font-bold text-3xl pb-8">
                Total Cost: ${total} CAD
              </div>
            </div>
            <div className="text-center flex flex-col gap-3 max-sm:block max-lg:w-[100%]">
              <p className="bg-slate-300 m-3 p-2 max-lg:m-1 rounded-md max-lg:text-xs">
                We grant permission to Canada Super Spelling Bee to photograph,
                video, and capture images of my child at spelling bee
                competitions. We grant Canada Super Spelling Bee the right to
                reproduce, use, exhibit, display, broadcast, and distribute
                these images in any media to promote and publicize its
                activities. More information on the{" "}
                <a
                  href="/consent-form"
                  className="text-blue-400 underline"
                  target="_blank"
                >
                  Media Release and Consent Form
                </a>
                .
              </p>
              <p className="text-xs">
                If you have any questions regarding the use of photos, please
                contact us at info@canadaspellingbee.com
              </p>
              <p className="text-lg pt-4">
                By typing your digital signature (full legal name) below, you
                are acknowledging and agreeing to the consent form and above
                clauses. You are also confirming that the information above is
                accurate.
              </p>
            </div>
            <div className="flex w-full max-sm:flex-col">
              <div className="flex flex-col w-full m-2">
                <div
                  name="parent-signature"
                  className="p-2 text-sm outline-none text-slate-600 border-b-2 border-blue-400 bg-slate-400 rounded-md"
                >
                  {parentSignature}
                </div>
                <label htmlFor="parent-signature" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Parent
                  Signature
                </label>
              </div>
              <div className="flex flex-col w-full m-2">
                <div
                  name="date"
                  className="p-2 text-sm text-slate-600 outline-none border-b-2 border-blue-400 bg-slate-400 rounded-md"
                >
                  {date}
                </div>
                <label htmlFor="date" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Date
                </label>
              </div>
            </div>
            <div
              type="submit"
              className="p-2 text-lg bg-slate-300 rounded-md px-5 mx-auto text-slate-500"
            >
              Submitted
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
