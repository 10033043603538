import { Link } from "react-router-dom";
import CampForm from "../components/CampForm";

export default function Camp() {
  return (
    <div>
      <Link to="/">
        <button className="p-2 mx-6 bg-orange-300 rounded-md text-white hover:bg-orange-400">
          Back
        </button>
      </Link>
      <CampForm></CampForm>
    </div>
  );
}
