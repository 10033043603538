import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Login from "./pages/Login";
import UserDashboard from "./pages/UserDashboard";
import Navbar from "./components/Navbar";
import { useAuthContext } from "./hooks/useAuthContext";
import Registration from "./pages/Registration";
import Camp from "./pages/Camp";
import Consent from "./pages/Consent";
import Payment from "./pages/Payment";
import Success from "./components/Success";
import CompletedNationalForm from "./components/CompletedNationalForm";
import CompletedCampForm from "./components/CompletedCampForm";

function App() {
  const { user } = useAuthContext();
  const [loggedIn, setLoggedIn] = useState(null);

  useEffect(() => {
    setLoggedIn(Boolean(user));
  }, [user]);

  return (
    <BrowserRouter>
      <Navbar></Navbar>
      {loggedIn !== null && (
        <Routes>
          <Route
            path="/"
            element={user ? <UserDashboard /> : <Navigate to="/login" />}
          ></Route>
          <Route
            path="/payment"
            element={user?.campForm ? <Payment /> : <Navigate to="/login" />}
          ></Route>
          <Route
            path="/success"
            element={user?.payment ? <Success /> : <Navigate to="/" />}
          ></Route>
          <Route
            path="/consent-form"
            element={user ? <Consent /> : <Navigate to="/login" />}
          ></Route>
          <Route
            path="/registration"
            element={
              user ? (
                !user.compForm ? (
                  <Registration />
                ) : (
                  <CompletedNationalForm />
                )
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route
            path="/camp"
            element={
              user ? (
                !user.campForm ? (
                  <Camp />
                ) : (
                  <CompletedCampForm />
                )
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route
            path="/login"
            element={user ? <Navigate to="/" /> : <Login />}
          ></Route>
          <Route
            path="*"
            element={user ? <Navigate to="/" /> : <Login />}
          ></Route>
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
