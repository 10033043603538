import { useState } from "react";
import { useLogin } from "../hooks/useLogin";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [bot, setBot] = useState("");
  const [pwdType, setPwdType] = useState("password");

  const { login, error, isLoading } = useLogin();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (bot) {
      console.log("error");
    } else {
      await login(username, password);
    }
  };

  return (
    <div>
      <form
        className="flex flex-col items-center justify-center bg-green-100 rounded-md shadow-sm shadow-emerald-800 w-[30%] py-12 mx-auto max-md:w-[75%]"
        onSubmit={(e) => handleSubmit(e)}
      >
        <h1 className="font-bold text-4xl pb-8 max-md:text-2xl">Login</h1>
        <input
          placeholder="Username"
          className="outline-none border-b-2 border-orange-500 rounded-md p-2 text-left focus:bg-orange-100 m-2 bg-transparent text-black transition-colors  max-md:text-xs"
          onChange={(e) => setUsername(e.target.value)}
          type="text"
        ></input>
        <div className="inline">
          <input
            placeholder="Password"
            className="outline-none border-b-2 border-orange-500 rounded-md p-2 text-left focus:bg-orange-100 m-2 bg-transparent text-black transition-colors translate-x-[10px] max-lg:translate-x-0 max-md:text-xs"
            onChange={(e) => setPassword(e.target.value)}
            type={pwdType}
          ></input>
          <i
            onClick={(e) =>
              pwdType === "password"
                ? setPwdType("text")
                : setPwdType("password")
            }
            className="fa-regular fa-eye translate-x-[-30px]  cursor-pointer max-lg:hidden"
          ></i>
        </div>
        <button
          type="submit"
          disabled={isLoading}
          className="bg-orange-400 p-4 w-[10em] rounded-md hover:bg-orange-600 transition-colors text-white mt-12 max-md:p-2 max-md:text-sm active:bg-orange-800"
        >
          Login
        </button>
        {error && (
          <div className="p-2 border-b-2 border-red-500 bg-red-400/80 text-red-900 m-4 text-center text-sm rounded-md">
            {error}
          </div>
        )}
      </form>
    </div>
  );
}
