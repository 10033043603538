import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { Link } from "react-router-dom";

export default function NationalForm() {
  const { user, dispatch } = useAuthContext();
  const userEmail = user.username;

  const [hasBeeExperience, setHasBeeExperience] = useState(false);
  const [otherGender, setOtherGender] = useState(false);
  const [spellerFirstName, setSpellerFirstName] = useState("");
  const [spellerLastName, setSpellerLastName] = useState("");
  const [dob, setDob] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [schoolAddress, setSchoolAddress] = useState("");
  const [grade, setGrade] = useState("JK");
  const [gender, setGender] = useState("Female");
  const [homePhone, setHomePhone] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [provinceState, setProvinceState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [disabilities, setDisabilities] = useState("");
  const [parentFirstName, setParentFirstName] = useState("");
  const [parentLastName, setParentLastName] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [parentPhone, setParentPhone] = useState("");
  const [emergencyFirstName, setEmergencyFirstName] = useState("");
  const [emergencyLastName, setEmergencyLastName] = useState("");
  const [emergencyRelationship, setEmergencyRelationship] = useState("");
  const [emergencyPhone, setEmergencyPhone] = useState("");
  const [beeExperience, setBeeExperience] = useState("");
  const [parentSignature, setParentSignature] = useState("");
  const [date, setDate] = useState("");
  const [file, setFile] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", file);
    formData.append("username", userEmail);

    const form = {
      speller: {
        firstName: spellerFirstName,
        lastName: spellerLastName,
        dob: dob,
        gender: gender,
        school: {
          name: schoolName,
          address: schoolAddress,
        },
        grade: grade,
        phone: homePhone,
        address: {
          address: homeAddress,
          country: country,
          city: city,
          province: provinceState,
          postalCode: postalCode,
        },
        hasBeeExperience: hasBeeExperience,
        beeExperience: beeExperience,
        disabilities: disabilities,
      },
      parent: {
        firstName: parentFirstName,
        lastName: parentLastName,
        phone: parentPhone,
        email: parentEmail,
      },
      emergencyContact: {
        firstName: emergencyFirstName,
        lastName: emergencyLastName,
        relationship: emergencyRelationship,
        phone: emergencyPhone,
      },
      image: userEmail,
      signature: parentSignature,
      date: date,
    };

    const object = await fetch(
      "https://forms-api.canadaspellingbee.com/api/user/uploadImage",
      {
        method: "POST",
        body: formData,
      }
    );

    const response = await fetch(
      "https://forms-api.canadaspellingbee.com/api/user/submitcomp",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userEmail, form }),
      }
    );

    if (!response.ok || !object.ok) {
      console.log("error");
    }

    if (response.ok && object.ok) {
      dispatch({ type: "SUBMIT_COMP", payload: null });

      window.location.href = "/";
    }
  };

  return (
    <main className="flex flex-col justify-center items-center m-8">
      <div className="p-6 border-2 border-dashed border-red-400 w-[60%] max-md:w-[100%] max-lg:w-[75%]">
        <form
          className="flex flex-col justify-center items-start"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div></div>
          <h1 className="py-2 text-3xl font-bold">Speller Information</h1>
          <h1 className="text-xs">
            Note: You must complete the form and press submit to complete the
            registration process.
          </h1>
          <h4 className="p-2 pb-3 text-1xl font-semibold text-red-500">
            * required
          </h4>
          <div className="flex gap-4 flex-wrap">
            <div className="flex flex-col">
              <input
                name="speller-first-name"
                className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                required
                onChange={(e) => setSpellerFirstName(e.target.value)}
              ></input>
              <label htmlFor="speller-first-name" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>First Name
              </label>
            </div>
            <div className="flex flex-col">
              <input
                name="speller-last-name"
                className="p-2 text-sm outline-none border-b-2  border-green-700 bg-green-100/50 focus:bg-green-100/50 rounded-md"
                required
                onChange={(e) => setSpellerLastName(e.target.value)}
              ></input>
              <label htmlFor="speller-last-name" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>Last Name
              </label>
            </div>

            <div className="flex flex-col">
              <input
                name="dob"
                type="date"
                className="p-2 text-sm outline-none border-b-2  focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                required
                onChange={(e) => setDob(e.target.value)}
              ></input>
              <label htmlFor="dob" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>Date of
                Birth
              </label>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <input
              name="school-name"
              className="p-2 text-sm outline-none border-b-2  focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
              required
              onChange={(e) => setSchoolName(e.target.value)}
            ></input>
            <label htmlFor="school-name" className="pb-[1vw] text-xs">
              <span className="text-red-500 font-semibold">*</span>School Name
            </label>
          </div>
          <div className="flex flex-col w-full">
            <input
              name="school-address"
              className="p-2 text-sm outline-none border-b-2  focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
              required
              onChange={(e) => setSchoolAddress(e.target.value)}
            ></input>
            <label htmlFor="school-address" className="pb-[1vw] text-xs">
              <span className="text-red-500 font-semibold">*</span>School
              Address
            </label>
          </div>
          <div className="flex gap-4 flex-wrap">
            <div className="flex items-center gap-1 pb-[1vw]">
              <label htmlFor="grade" className="text-sm">
                <span className="text-red-500 font-semibold">*</span>Grade:
              </label>
              <select
                name="grade"
                className="outline-none border-b-2 focus:bg-green-100/50 rounded-md border-green-700 bg-green-100/50 p-2"
                required
                onChange={(e) => setGrade(e.target.value)}
              >
                <option value="JK">JK</option>
                <option value="SK">SK</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
              </select>
            </div>
            <div className="flex items-center gap-1 pb-[1vw] flex-wrap">
              <label htmlFor="gender" className="text-sm">
                <span className="text-red-500 font-semibold">*</span>Gender:
              </label>
              <select
                name="gender"
                className="outline-none border-b-2  focus:bg-green-100/50 rounded-md border-green-700 bg-green-100/50 p-2"
                onChange={(e) => {
                  if (e.target.value === "Other") {
                    setOtherGender(true);
                  } else {
                    setOtherGender(false);
                    setGender(e.target.value);
                  }
                }}
                required
              >
                <option value="Female">Female</option>
                <option value="Male">Male</option>
                <option value="Other">Other</option>
              </select>
              {otherGender && (
                <div>
                  <div className="flex flex-col">
                    <input
                      name="other-gender"
                      className="p-2 text-sm outline-none border-b-2  focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md mb-0"
                      placeholder="Please specify (Optional)"
                      onChange={(e) => setGender(e.target.value)}
                    ></input>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-4 flex-wrap">
            <div className="flex flex-col">
              <input
                name="home-phone"
                className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md w-max"
                required
                type="tel"
                onChange={(e) => setHomePhone(e.target.value)}
              ></input>
              <label htmlFor="home-phone" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>Home Phone
              </label>
            </div>
            <div className="flex flex-col">
              <input
                name="home-address"
                className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md w-[30vw] max-sm:w-max"
                required
                onChange={(e) => setHomeAddress(e.target.value)}
              ></input>
              <label htmlFor="home-address" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>Home
                Address
              </label>
            </div>
          </div>
          <div className="flex gap-4 flex-wrap">
            <div className="flex flex-col">
              <input
                name="city"
                className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                required
                onChange={(e) => setCity(e.target.value)}
              ></input>
              <label htmlFor="city" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>City
              </label>
            </div>
            <div className="flex flex-col">
              <input
                name="province-state"
                className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                required
                onChange={(e) => setProvinceState(e.target.value)}
              ></input>
              <label htmlFor="province-state" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>
                Province/State
              </label>
            </div>
            <div className="flex flex-col">
              <input
                name="country"
                className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                required
                onChange={(e) => setCountry(e.target.value)}
              ></input>
              <label htmlFor="country" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>Country
              </label>
            </div>

            <div className="flex flex-col">
              <input
                name="postal-code"
                className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                required
                onChange={(e) => setPostalCode(e.target.value)}
              ></input>
              <label htmlFor="postal-code" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>Postal Code
              </label>
            </div>
          </div>
          <label htmlFor="disabilities" className="text-sm">
            Physical and/or Mental Disabilities
          </label>
          <textarea
            name="disabilities"
            className="w-[60%] outline-none border-2 border-green-700 bg-green-100/50 rounded-md h-[6em] resize-none"
            onChange={(e) => setDisabilities(e.target.value)}
          ></textarea>
          <h1 className="py-5 text-3xl font-bold">Parent Information</h1>
          <div className="flex gap-4 flex-wrap">
            <div className="flex flex-col">
              <input
                name="parent-first-name"
                className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                required
                onChange={(e) => setParentFirstName(e.target.value)}
              ></input>
              <label htmlFor="parent-first-name" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>First Name
              </label>
            </div>
            <div className="flex flex-col">
              <input
                name="parent-last-name"
                className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                required
                onChange={(e) => setParentLastName(e.target.value)}
              ></input>
              <label htmlFor="parent-last-name" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>Last Name
              </label>
            </div>
          </div>
          <div className="flex gap-4 flex-wrap">
            <div className="flex flex-col">
              <input
                name="parent-email"
                className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                required
                onChange={(e) => setParentEmail(e.target.value)}
              ></input>
              <label htmlFor="parent-email" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>Email
              </label>
            </div>
            <div className="flex flex-col">
              <input
                name="parent-phone"
                className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                required
                type="tel"
                onChange={(e) => setParentPhone(e.target.value)}
              ></input>
              <label htmlFor="parent-phone" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>Phone
              </label>
            </div>
          </div>
          <h1 className="py-5 text-3xl font-bold">
            Emergency Contact Information
          </h1>
          <div className="flex gap-4 flex-wrap">
            <div className="flex flex-col">
              <input
                name="emergency-first-name"
                className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                required
                onChange={(e) => setEmergencyFirstName(e.target.value)}
              ></input>
              <label
                htmlFor="emergency-first-name"
                className="pb-[1vw] text-xs"
              >
                <span className="text-red-500 font-semibold">*</span>First Name
              </label>
            </div>
            <div className="flex flex-col">
              <input
                name="emergency-last-name"
                className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                required
                onChange={(e) => setEmergencyLastName(e.target.value)}
              ></input>
              <label htmlFor="emergency-last-name" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>Last Name
              </label>
            </div>
            <div className="flex flex-col">
              <input
                name="emergency-relationship"
                className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                required
                onChange={(e) => setEmergencyRelationship(e.target.value)}
              ></input>
              <label
                htmlFor="emergency-relationship"
                className="pb-[1vw] text-xs"
              >
                <span className="text-red-500 font-semibold">*</span>
                Relationship with Speller
              </label>
            </div>
            <div className="flex flex-col">
              <input
                name="emergency-phone"
                className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                required
                type="tel"
                onChange={(e) => setEmergencyPhone(e.target.value)}
              ></input>
              <label htmlFor="emergency-phone" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>Phone
              </label>
            </div>
          </div>
          <label htmlFor="has-bee-experience">
            Has your child participated in a spelling bee competition in the
            past?
          </label>
          <div className="flex items-center flex-col justify-center p-2">
            <div>
              <label htmlFor="has-bee-experience">Yes</label>
              <input
                name="has-bee-experience"
                type="radio"
                value="yes"
                onChange={(e) => setHasBeeExperience(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="has-bee-experience">No</label>
              <input
                name="has-bee-experience"
                type="radio"
                defaultChecked
                value="no"
                onChange={(e) => setHasBeeExperience(e.target.value)}
              ></input>
            </div>
          </div>
          {hasBeeExperience == "yes" && (
            <>
              <label htmlFor="bee-experience">
                Please specify competition(s) and ranking(s)
              </label>
              <textarea
                onChange={(e) => setBeeExperience(e.target.value)}
                name="bee-experience"
                className="w-[60%] outline-none border-2 border-green-700 bg-green-100/50 rounded-md h-[6em] resize-none"
              ></textarea>
            </>
          )}
          <div className="flex py-4 flex-wrap">
            <label htmlFor="speller-image ">
              <span className="text-red-500 font-semibold">*</span>
              Upload Speller's Personal Photo (Accepted Extensions: JPEG, JPG,
              PNG)
            </label>
            <input
              type="file"
              required
              name="speller-image"
              id="speller-image"
              onChange={(e) => setFile(e.target.files[0])}
              accept="image/*"
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-500 dark:border-gray-600 dark:placeholder-gray-400"
            ></input>
            {file && (
              <div>
                <img
                  src={URL.createObjectURL(file)}
                  alt="Uploaded Image"
                  className="w-[50px] p-2"
                />
              </div>
            )}
          </div>
          <div className="text-center flex flex-col gap-3 max-sm:block max-lg:w-[100%]">
            <p className="bg-slate-300 m-3 p-2 max-lg:m-1 rounded-md max-lg:text-xs">
              We grant permission to Canada Super Spelling Bee to photograph,
              video, and capture images of my child at spelling bee
              competitions. We grant Canada Super Spelling Bee the right to
              reproduce, use, exhibit, display, broadcast, and distribute these
              images in any media to promote and publicize its activities. More
              information on the{" "}
              <a
                href="/consent-form"
                className="text-blue-400 underline"
                target="_blank"
              >
                Media Release and Consent Form
              </a>
              .
            </p>
            <p className="text-xs">
              If you have any questions regarding the use of photos, please
              contact us at info@canadaspellingbee.com
            </p>
            <p className="text-lg pt-4">
              By typing your digital signature (full legal name) below, you are
              acknowledging and agreeing to the consent form and above clauses.
              You are also confirming that the information above is accurate.
            </p>
            <div className="flex max-sm:flex-col">
              <div className="flex flex-col w-full m-2">
                <input
                  name="parent-signature"
                  className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                  required
                  onChange={(e) => setParentSignature(e.target.value)}
                ></input>
                <label htmlFor="parent-signature" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Parent
                  Signature
                </label>
              </div>
              <div className="flex flex-col w-full m-2">
                <input
                  name="date"
                  type="date"
                  className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                  required
                  onChange={(e) => setDate(e.target.value)}
                ></input>
                <label htmlFor="date" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Date
                </label>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="p-2 text-lg bg-green-300 rounded-md px-5 mx-auto hover:bg-green-500"
          >
            Submit
          </button>
        </form>
      </div>
    </main>
  );
}
