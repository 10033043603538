import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

export default function Success() {
  return (
    <div>
      <h1 className="text-2xl text-center">Payment Successful!</h1>
      <p className="text-center">
        Thank you for registering your extra information and paying for the
        accommodations!
      </p>
      <div className="flex justify-center">
        <Link to="/">
          <button className="p-2 mx-6 bg-orange-300 rounded-md text-white hover:bg-orange-400">
            Back
          </button>
        </Link>
      </div>
    </div>
  );
}
