import { useEffect, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { Link } from "react-router-dom";

export default function CompletedNationalForm() {
  const { user } = useAuthContext();
  const userEmail = user.username;

  const [hasBeeExperience, setHasBeeExperience] = useState(false);
  const [otherGender, setOtherGender] = useState(false);
  const [spellerFirstName, setSpellerFirstName] = useState("");
  const [spellerLastName, setSpellerLastName] = useState("");
  const [dob, setDob] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [schoolAddress, setSchoolAddress] = useState("");
  const [grade, setGrade] = useState("JK");
  const [gender, setGender] = useState("Female");
  const [homePhone, setHomePhone] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [provinceState, setProvinceState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [disabilities, setDisabilities] = useState("");
  const [parentFirstName, setParentFirstName] = useState("");
  const [parentLastName, setParentLastName] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [parentPhone, setParentPhone] = useState("");
  const [emergencyFirstName, setEmergencyFirstName] = useState("");
  const [emergencyLastName, setEmergencyLastName] = useState("");
  const [emergencyRelationship, setEmergencyRelationship] = useState("");
  const [emergencyPhone, setEmergencyPhone] = useState("");
  const [beeExperience, setBeeExperience] = useState("");
  const [parentSignature, setParentSignature] = useState("");
  const [date, setDate] = useState("");
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    let json;
    let response;
    const fetchUserInformation = async () => {
      response = await fetch(
        `https://forms-api.canadaspellingbee.com/api/user/get${userEmail}`
      );
      json = await response.json();
      if (!response.ok) {
        return;
      }

      const information = json?.user?.information;

      setImageUrl(json?.url);

      if (information) {
        setSpellerFirstName(information.speller.firstName);
        setSpellerLastName(information.speller.lastName);
        setDob(information.speller.dob.split("T")[0]);
        setSchoolName(information.speller.school.name);
        setSchoolAddress(information.speller.school.address);
        setGrade(information.speller.grade);
        setGender(information.speller.gender);
        setHomePhone(information.speller.phone);
        setHomeAddress(information.speller.address.address);
        setProvinceState(information.speller.address.province);
        setCountry(information.speller.address.country);
        setCity(information.speller.address.city);
        setPostalCode(information.speller.address.postalCode);
        setDisabilities(information.speller.disabilities);
        setParentFirstName(information.parent.firstName);
        setParentLastName(information.parent.lastName);
        setParentEmail(information.parent.email);
        setParentPhone(information.parent.phone);
        setEmergencyFirstName(information.emergencyContact.firstName);
        setEmergencyLastName(information.emergencyContact.lastName);
        setEmergencyRelationship(information.emergencyContact.relationship);
        setEmergencyPhone(information.emergencyContact.phone);
        setBeeExperience(information.speller.beeExperience);
        setParentSignature(information.signature);
        setDate(information.date);
      }
    };

    fetchUserInformation();
  }, []);

  return (
    <div>
      <Link to="/">
        <button className="p-2 mx-6 bg-orange-300 rounded-md text-white hover:bg-orange-400">
          Back
        </button>
      </Link>
      <main className="flex flex-col justify-center items-center m-8">
        <div className="p-6 border-2 border-dashed border-red-400 w-[60%] max-md:w-[100%] max-lg:w-[75%]">
          <div className="flex flex-col justify-center items-start">
            <div className="m-auto text-2xl p-3 text-green-900 font-bold text-center">
              You have completed this form!<br></br>This is what we received:
            </div>
            <h1 className="py-2 text-3xl font-bold">Speller Information</h1>
            <h4 className="p-2 pb-3 text-1xl font-semibold text-red-500">
              * required
            </h4>
            <div className="flex gap-4 flex-wrap">
              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                  {spellerFirstName}
                </div>
                <label
                  htmlFor="speller-first-name"
                  className="pb-[1vw] text-xs"
                >
                  <span className="text-red-500 font-semibold">*</span>First
                  Name
                </label>
              </div>
              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                  {spellerLastName}
                </div>
                <label htmlFor="speller-last-name" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Last Name
                </label>
              </div>

              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                  {dob}
                </div>
                <label htmlFor="dob" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Date of
                  Birth
                </label>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                {schoolName}
              </div>
              <label htmlFor="school-name" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>School Name
              </label>
            </div>
            <div className="flex flex-col w-full">
              <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                {schoolAddress}
              </div>
              <label htmlFor="school-address" className="pb-[1vw] text-xs">
                <span className="text-red-500 font-semibold">*</span>School
                Address
              </label>
            </div>
            <div className="flex gap-4 flex-wrap">
              <div className="flex items-center gap-1 pb-[1vw]">
                <label htmlFor="grade" className="text-sm">
                  <span className="text-red-500 font-semibold">*</span>Grade:
                </label>
                <div className="outline-none border-b-2 rounded-md border-green-700 bg-green-100/50 p-2 text-slate-600">
                  {grade}
                </div>
              </div>
              <div className="flex items-center gap-1 pb-[1vw]">
                <label htmlFor="gender" className="text-sm">
                  <span className="text-red-500 font-semibold">*</span>Gender:
                </label>
                <div className="outline-none border-b-2 rounded-md border-green-700 bg-green-100/50 p-2 text-slate-600">
                  {gender}
                </div>
              </div>
            </div>
            <div className="flex gap-4 flex-wrap">
              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md w-max text-slate-600">
                  {homePhone}
                </div>
                <label htmlFor="home-phone" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Home
                  Phone
                </label>
              </div>
              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md w-max text-slate-600">
                  {homeAddress}
                </div>
                <label htmlFor="home-address" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Home
                  Address
                </label>
              </div>
            </div>
            <div className="flex gap-4 flex-wrap">
              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                  {city}
                </div>
                <label htmlFor="city" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>City
                </label>
              </div>
              <div className="flex flex-col">
                <div
                  className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600"
                  text-slate-600
                >
                  {provinceState}
                </div>
                <label htmlFor="province-state" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>
                  Province/State
                </label>
              </div>
              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                  {country}
                </div>
                <label htmlFor="country" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Country
                </label>
              </div>

              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                  {postalCode}
                </div>
                <label htmlFor="postal-code" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Postal
                  Code
                </label>
              </div>
            </div>
            <label htmlFor="disabilities" className="text-sm">
              Physical and/or Mental Disabilities
            </label>
            <div className="w-[60%] outline-none border-2 border-green-700 bg-green-100/50 rounded-md h-[6em] resize-none text-slate-600">
              {disabilities}
            </div>
            <h1 className="py-5 text-3xl font-bold">Parent Information</h1>
            <div className="flex gap-4 flex-wrap">
              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                  {parentFirstName}
                </div>
                <label htmlFor="parent-first-name" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>First
                  Name
                </label>
              </div>
              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                  {parentLastName}
                </div>
                <label htmlFor="parent-last-name" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Last Name
                </label>
              </div>
            </div>
            <div className="flex gap-4 flex-wrap">
              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                  {parentEmail}
                </div>
                <label htmlFor="parent-email" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Email
                </label>
              </div>
              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                  {parentPhone}
                </div>
                <label htmlFor="parent-phone" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Phone
                </label>
              </div>
            </div>
            <h1 className="py-5 text-3xl font-bold">
              Emergency Contact Information
            </h1>
            <div className="flex gap-4 flex-wrap">
              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                  {emergencyFirstName}
                </div>
                <label
                  htmlFor="emergency-first-name"
                  className="pb-[1vw] text-xs"
                >
                  <span className="text-red-500 font-semibold">*</span>First
                  Name
                </label>
              </div>
              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                  {emergencyLastName}
                </div>
                <label
                  htmlFor="emergency-last-name"
                  className="pb-[1vw] text-xs"
                >
                  <span className="text-red-500 font-semibold">*</span>Last Name
                </label>
              </div>
              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                  {emergencyRelationship}
                </div>
                <label
                  htmlFor="emergency-relationship"
                  className="pb-[1vw] text-xs"
                >
                  <span className="text-red-500 font-semibold">*</span>
                  Relationship with Speller
                </label>
              </div>
              <div className="flex flex-col">
                <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                  {emergencyPhone}
                </div>
                <label htmlFor="emergency-phone" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Phone
                </label>
              </div>
            </div>
            <label htmlFor="has-bee-experience">Bee Experience:</label>

            <div className="w-[60%] outline-none border-2 border-green-700 bg-green-100/50 rounded-md h-[6em] resize-none  text-slate-600 ">
              {beeExperience}
            </div>

            <div className="flex py-4 flex-wrap flex-col">
              <label htmlFor="speller-image ">
                Speller's Personal Photo (Max. 1000KB Image Size)
              </label>
              <img
                src={imageUrl}
                alt="Uploaded Image"
                className="w-[200px] p-2"
              />
            </div>
            <div className="text-center flex flex-col gap-3 max-sm:block max-lg:w-[100%]">
              <p className="bg-slate-300 m-3 p-2 max-lg:m-1 rounded-md max-lg:text-xs">
                We grant permission to Canada Super Spelling Bee to photograph,
                video, and capture images of my child at spelling bee
                competitions. We grant Canada Super Spelling Bee the right to
                reproduce, use, exhibit, display, broadcast, and distribute
                these images in any media to promote and publicize its
                activities. More information on the{" "}
                <a
                  href="/consent-form"
                  className="text-blue-400 underline"
                  target="_blank"
                >
                  Media Release and Consent Form
                </a>
                .
              </p>
              <p className="text-xs">
                If you have any questions regarding the use of photos, please
                contact us at info@canadaspellingbee.com
              </p>
              <p className="text-lg pt-4">
                By typing your digital signature (full legal name) below, you
                are acknowledging and agreeing to the consent form and above
                clauses. You are also confirming that the information above is
                accurate.
              </p>
              <div className="flex max-sm:flex-col">
                <div className="flex flex-col w-full m-2">
                  <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                    {parentSignature}
                  </div>
                  <label
                    htmlFor="parent-signature"
                    className="pb-[1vw] text-xs"
                  >
                    <span className="text-red-500 font-semibold">*</span>Parent
                    Signature
                  </label>
                </div>
                <div className="flex flex-col w-full m-2">
                  <div className="p-2 text-sm outline-none border-b-2 border-green-700 bg-green-100/50 rounded-md text-slate-600">
                    {date}
                  </div>
                  <label htmlFor="date" className="pb-[1vw] text-xs">
                    <span className="text-red-500 font-semibold">*</span>Date
                  </label>
                </div>
              </div>
            </div>
            <div
              type="submit"
              className="p-2 text-lg bg-slate-300 rounded-md px-5 mx-auto text-slate-500"
            >
              Submitted
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
