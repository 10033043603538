import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useAuthContext } from "../hooks/useAuthContext";

export default function CheckoutForm() {
  const [message, setMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const { user, dispatch } = useAuthContext();
  const userEmail = user.username;

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const verifyPayment = async () => {
      await fetch(
        "https://forms-api.canadaspellingbee.com/api/user/submitpayment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userEmail }),
        }
      );

      dispatch({ type: "SUBMIT_PAYMENT", payload: null });
    };

    verifyPayment();

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/success`,
      },
    });

    if (error) {
      setMessage(error.message);
      await fetch(
        "https://forms-api.canadaspellingbee.com/api/user/unsubmitpayment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userEmail }),
        }
      );
      dispatch({ type: "UNSUBMIT_PAYMENT", payload: null });
    }

    setIsProcessing(false);
  };

  return (
    <div className="flex justify-center">
      <form
        onSubmit={handleSubmit}
        id="payment-form"
        className="w-[70%] max-md:w-[100%]"
      >
        <PaymentElement />
        <button
          disabled={isProcessing}
          id="submit"
          className={`bg-purple-600 text-white p-4 rounded-md my-5 ${
            isProcessing ? "animate-pulse" : "animate-none"
          }`}
        >
          <span id="button-text">
            {isProcessing ? "Processing..." : "Pay Now"}
          </span>
        </button>
        {message && <div className="text-red-600">{message}</div>}
      </form>
    </div>
  );
}
