import { Link } from "react-router-dom";

export default function Consent() {
  return (
    <div className="p-12">
      <h1 className="text-4xl text-center">
        Student Consent, Acknowledgement, Waiver and Release of Liability
      </h1>
      <p className="p-4">
        Students may be photographed and/or recorded to promote Canada Super
        Spelling Bee (CSSB) programs and events. Students may be featured in
        print and/or social media to increase awareness for CSSB. These types of
        media include: brochures, websites, social media, profile booklet and
        other types of media.
      </p>
      <p className="p-4">
        I consent to the use of my child’s name, photo, video and/or audio
        recordings to be used for public relations or to be displayed on the
        CSSB website and social media pages and/or shared with other students’
        families; and for my child to be photographed, recorded or interviewed
        by the media as outlined above.
      </p>
      <p className="p-4">
        I certify that I have read the Student Media Consent &amp; Release Form
        and I fully understand its terms and conditions.
      </p>
      <p className="p-4">
        I, the undersigned, authorize CSSB and its employees and
        representatives’ permission to print, photograph, and record your child
        for use in audio, video, film, or other media platforms.{" "}
      </p>
      <p className="p-4">
        I hereby give my approval for my child’s participation in any and all
        activities prepared by Canada Super Spelling Bee during the selected
        program. In exchange for the acceptance of said child’s candidacy by
        Canada Super Spelling Bee Inc., I assume all risk and hazards incidental
        to the conduct of the activities, and release, absolve and hold harmless
        Canada Super Spelling bee and all its respective officers, agents, and
        representatives from any and all liability for injuries to said child
        arising out of traveling to, participating in, or returning from
        selected program sessions.
      </p>
      <p className="p-4">
        I hereby give my approval for my child’s participation in any and all
        activities prepared by Canada Super Spelling Bee during the selected
        program. In exchange for the acceptance of said child’s candidacy by
        Canada Super Spelling Bee Inc., I assume all risk and hazards incidental
        to the conduct of the activities, and release, absolve and hold harmless
        Canada Super Spelling bee and all its respective officers, agents, and
        representatives from any and all liability for injuries to said child
        arising out of traveling to, participating in, or returning from
        selected program sessions.
      </p>
      <p className="p-4">
        In case of injury to said child, I hereby waive all claims against
        Canada Super Spelling Bee Inc., including all executives, directors,
        managers, staffs, teachers, coaches, counsellors, volunteers, agents,
        partners, and affiliates, all participants, sponsoring agencies, medias,
        advertisers, and, if applicable, owners and lessors of premises used to
        conduct the event. There is a risk of being injured that is inherent in
        all physical activities, including all sports and travel activities.
        Some of these injuries include, but are not limited to, the risk of
        fractures, paralysis, or death.
      </p>

      <h1 className="text-2xl text-center">Medical</h1>
      <p className="p-4">
        I declare that the information I have provided on this form is complete
        and correct to the best of my knowledge and that I will notify the
        organizers if any changes occur. I authorize the organizers or any
        employee of CSSB who is with my child, to give consent where it is
        impractical to communicate with me, and agree to my child receiving such
        medical or surgical treatment as may be deemed necessary.
      </p>
      <p className="p-4">
        I give permission for CSSB to pass this information to a third party
        (e.g. doctors, hospitals) to facilitate the medical treatment of my
        child. I give permission for CSSB to retain this form for statutory
        archival requirements.
      </p>
      <p className="p-4">
        Canada Super Spelling Bee Inc.<br></br>
        <a href="www.canadaspellingbee.com">www.canadaspellingbee.com</a>
        <br></br> 613-422-6688
      </p>
    </div>
  );
}
