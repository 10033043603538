import { PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import CheckoutForm from "../components/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { useAuthContext } from "../hooks/useAuthContext";
import { Link, Navigate, useNavigate } from "react-router-dom";

export default function Payment() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const { user, dispatch } = useAuthContext();
  const userEmail = user.username;

  const [paymentTotal, setPaymentTotal] = useState(false);

  useEffect(() => {
    if (user?.payment) {
      window.location.href = "/success";
    }

    const setPaymentIntent = async (paymentNum, email) => {
      fetch("https://forms-api.canadaspellingbee.com/create-payment-intent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ total: paymentNum, customer: userEmail, email }),
      }).then(async (res) => {
        const { clientSecret } = await res.json();

        setClientSecret(clientSecret);
      });
    };

    fetch("https://forms-api.canadaspellingbee.com/config").then(
      async (res) => {
        const { publishableKey } = await res.json();
        setStripePromise(loadStripe(publishableKey));
      }
    );

    let json;
    let response;
    const fetchUserInformation = async () => {
      response = await fetch(
        `https://forms-api.canadaspellingbee.com/api/user/get${userEmail}`
      );
      json = await response.json();
      if (!response.ok) {
        return;
      }

      setPaymentTotal(json.user?.extra?.total);

      setPaymentIntent(
        json.user?.extra?.total,
        json.user?.information?.parent?.email
      );
    };

    fetchUserInformation();
  }, []);

  return (
    <div>
      <Link to="/">
        <button className="p-2 mx-6 bg-orange-300 rounded-md text-white hover:bg-orange-400">
          Back
        </button>
      </Link>
      <div className="bg-slate-100 w-[80%] m-auto rounded-md my-[3em] p-8">
        <h1 className="text-4xl text-center font-bold p-9 text-purple-500 max-md:text-2xl">
          Stripe Payment
        </h1>

        <h2 className="text-2xl text-center font-bold p-9 text-purple-500">
          Total: ${paymentTotal} CAD
        </h2>
        {stripePromise && clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm />
          </Elements>
        )}
      </div>
    </div>
  );
}
