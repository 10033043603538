import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";

export default function CampForm() {
  const { user, dispatch } = useAuthContext();
  const userEmail = user.username;

  const [numTrays, setNumTrays] = useState(0);

  const [SAdult, setSAdult] = useState(0);
  const [MChild, setMChild] = useState(0);
  const [LChild, setLChild] = useState(0);
  const [XLChild, setXLChild] = useState(0);
  const [XXLChild, setXXLChild] = useState(0);
  const [XXXLChild, setXXXLChild] = useState(0);
  const [MAdult, setMAdult] = useState(0);
  const [LAdult, setLAdult] = useState(0);
  const [XLAdult, setXLAdult] = useState(0);
  const [XXLAdult, setXXLAdult] = useState(0);
  const [XXXLAdult, setXXXLAdult] = useState(0);
  const [XXXXLAdult, setXXXXLAdult] = useState(0);
  const [XXXXXLAdult, setXXXXXLAdult] = useState(0);

  const [parentSignature, setParentSignature] = useState("");
  const [date, setDate] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedRadioButton = document.querySelector(
      'input[name="summer-camp"]:checked'
    );
    const dateSelection = selectedRadioButton ? selectedRadioButton.id : null;

    const order = Array.from({ length: numTrays }).map((_, index) => {
      const mainSelection = document.querySelector(
        `input[name="main-selection${index + 1}"]:checked`
      );
      const side1Selection = document.querySelector(
        `input[name="side1-selection${index + 1}"]:checked`
      );
      const side2Selection = document.querySelector(
        `input[name="side2-selection${index + 1}"]:checked`
      );
      const drinkSelection = document.querySelector(
        `input[name="drink-selection${index + 1}"]:checked`
      );

      return {
        mainSelection: mainSelection
          ? mainSelection.id +
            (document.getElementById("dietary-restrictions" + (index + 1)).value
              .length > 0
              ? " (Dietary Restriction: " +
                document.getElementById("dietary-restrictions" + (index + 1))
                  .value +
                ")"
              : "")
          : null,
        side1Selection: side1Selection ? side1Selection.id : null,
        side2Selection: side2Selection ? side2Selection.id : null,
        drinkSelection: drinkSelection ? drinkSelection.id : null,
      };
    });

    const total =
      numTrays * 20 +
      (25 *
        (XXXXXLAdult +
          XXXXLAdult +
          XXXLChild +
          XXLChild +
          XLChild +
          LChild +
          MChild +
          XXXLAdult +
          XXLAdult +
          XLAdult +
          LAdult +
          MAdult +
          SAdult -
          1) >
      0
        ? 25 *
          (XXXXXLAdult +
            XXXXLAdult +
            XXXLChild +
            XXLChild +
            XLChild +
            LChild +
            MChild +
            XXXLAdult +
            XXLAdult +
            XLAdult +
            LAdult +
            MAdult +
            SAdult -
            1)
        : 0) +
      (dateSelection === "yes" ? 299 : 0);

    const form = {
      dateSelection,
      numTrays,
      order,
      total,
      shirts: {
        SAdult,
        MChild,
        LChild,
        XLChild,
        XXLChild,
        XXXLChild,
        MAdult,
        LAdult,
        XLAdult,
        XXLAdult,
        XXXLAdult,
        XXXXLAdult,
        XXXXXLAdult,
      },
      signature: parentSignature,
      date: date,
    };

    const response = await fetch(
      "https://forms-api.canadaspellingbee.com/api/user/submitcamp",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userEmail, form }),
      }
    );

    const json = await response.json();

    if (!response.ok) {
      console.log(json.error);
    }

    if (response.ok) {
      dispatch({ type: "SUBMIT_CAMP", payload: null });

      if (total == 0) {
        dispatch({ type: "SUBMIT_PAYMENT", payload: null });
        window.location.href = "/success";
      }

      window.location.href = "/camp";
    }
  };

  return (
    <main className="flex flex-col justify-center items-center m-8">
      <div className="p-6 border-2 border-dashed border-red-400 w-[60%] max-md:w-[100%] max-lg:w-[75%]">
        <form
          className="flex flex-col justify-center items-start"
          onSubmit={(e) => handleSubmit(e)}
        >
          <h1 className="py-5 text-3xl font-bold">
            National Bee Extra Registration
          </h1>
          <h1 className="text-xl font-semibold">
            Order Lunch Trays for the National Bee
          </h1>
          <h1 className="text-sm">$20 CAD per meal, HST included</h1>
          <div className="flex gap-x-7 flex-col pb-12">
            <div className="flex flex-col items-center">
              <input
                name="num-trays"
                className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-blue-100 rounded-md w-full"
                required
                type="number"
                min="0"
                defaultValue="0"
                onChange={(e) => setNumTrays(Number(e.target.value))}
              ></input>
              <label htmlFor="num-trays" className="pb-[1vw] text-xs w-fit">
                Number of Meals
              </label>
            </div>
            {Array.from({ length: numTrays }).map((_, index) => (
              <section
                key={index}
                className=" border-slate-500 border-2 p-2 m-1"
              >
                <h1 className="font-semibold">Order {index + 1}</h1>
                <div className="flex items-start gap-16 flex-wrap">
                  <div className="flex items-start gap-1 pb-[1vw] flex-col">
                    <label htmlFor="date-selection" className="text-sm">
                      Select Type:
                    </label>
                    <div className="flex gap-2">
                      <input
                        type="radio"
                        id="egg-salad"
                        name={"main-selection" + (index + 1)}
                        defaultChecked={true}
                      ></input>
                      <label>Egg Salad</label>
                    </div>
                    <div className="flex gap-2">
                      <input
                        type="radio"
                        id="chicken-salad"
                        name={"main-selection" + (index + 1)}
                      ></input>

                      <label>Chicken Salad</label>
                    </div>
                    <div className="flex gap-2">
                      <input
                        type="radio"
                        id="california-veggie-wrap"
                        name={"main-selection" + (index + 1)}
                      ></input>

                      <label>California Veggie Wrap</label>
                    </div>
                    <div className="flex gap-2">
                      <input
                        type="radio"
                        id="turkey-swiss"
                        name={"main-selection" + (index + 1)}
                      ></input>

                      <label>Turkey and Swiss on a Kaiser</label>
                    </div>
                  </div>
                  <div className="flex items-start gap-1 pb-[1vw] flex-col">
                    <label htmlFor="date-selection" className="text-sm">
                      Select Side 1:
                    </label>
                    <div className="flex gap-2">
                      <input
                        type="radio"
                        id="salad"
                        name={"side1-selection" + (index + 1)}
                        defaultChecked={true}
                      ></input>
                      <label>Salad</label>
                    </div>
                    <div className="flex gap-2">
                      <input
                        type="radio"
                        id="chips"
                        name={"side1-selection" + (index + 1)}
                      ></input>

                      <label>Chips</label>
                    </div>
                  </div>
                  <div className="flex items-start gap-1 pb-[1vw] flex-col">
                    <label htmlFor="date-selection" className="text-sm">
                      Select Side 2:
                    </label>
                    <div className="flex gap-2">
                      <input
                        type="radio"
                        id="fruit"
                        name={"side2-selection" + (index + 1)}
                        defaultChecked={true}
                      ></input>
                      <label>Fresh Fruit</label>
                    </div>
                    <div className="flex gap-2">
                      <input
                        type="radio"
                        id="cookie"
                        name={"side2-selection" + (index + 1)}
                      ></input>

                      <label>Cookie</label>
                    </div>
                  </div>
                  <div className="flex items-start gap-1 pb-[1vw] flex-col">
                    <label htmlFor="date-selection" className="text-sm">
                      Select Drink:
                    </label>
                    <div className="flex gap-2">
                      <input
                        type="radio"
                        id="water"
                        name={"drink-selection" + (index + 1)}
                        defaultChecked={true}
                      ></input>
                      <label>Bottled Water</label>
                    </div>
                    <div className="flex gap-2">
                      <input
                        type="radio"
                        id="soft-drink"
                        name={"drink-selection" + (index + 1)}
                      ></input>

                      <label>Canned Soft Drink</label>
                    </div>
                  </div>
                </div>
                <div className="flex items-start gap-1 pb-[1vw] flex-col">
                  <label htmlFor={"dietary-restrictions" + (index + 1)}>
                    Special Dietary Restrictions:
                  </label>
                  <input
                    className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                    id={"dietary-restrictions" + (index + 1)}
                  ></input>
                </div>
              </section>
            ))}
            <div>Total Meals in Cart: {numTrays}</div>
            <div>
              Total Cost of Meals: <b>${numTrays * 20} CAD</b>
            </div>
          </div>
          <h1 className="text-xl font-semibold">Order CSSB Shirts</h1>
          <h1 className="text-sm">
            First shirt is free for the speller, every other added shirt is $25
            CAD. Will be available for pick up at the National Bee.
          </h1>
          <h1 className="text-sm">Number of Shirts:</h1>
          <div className="pb-12">
            <div className="flex gap-x-7 flex-wrap ">
              <div className="flex flex-col w-[10%] max-md:w-[20%] items-center">
                <input
                  name="num-SAdult"
                  className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-blue-100 rounded-md w-full"
                  required
                  type="number"
                  min="0"
                  defaultValue="0"
                  onChange={(e) => setSAdult(Number(e.target.value))}
                ></input>
                <label htmlFor="num-SAdult" className="pb-[1vw] text-xs w-fit">
                  S (Adult)
                </label>
              </div>
              <div className="flex flex-col w-[10%] max-md:w-[20%] items-center">
                <input
                  name="num-MChild"
                  className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-blue-100 rounded-md w-full"
                  required
                  type="number"
                  min="0"
                  defaultValue="0"
                  onChange={(e) => setMChild(Number(e.target.value))}
                ></input>
                <label htmlFor="num-MChild" className="pb-[1vw] text-xs w-fit">
                  M (Child)
                </label>
              </div>
              <div className="flex flex-col w-[10%] max-md:w-[20%] items-center">
                <input
                  name="num-MAdult"
                  className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-blue-100 rounded-md w-full"
                  required
                  type="number"
                  min="0"
                  defaultValue="0"
                  onChange={(e) => setMAdult(Number(e.target.value))}
                ></input>
                <label htmlFor="num-MAdult" className="pb-[1vw] text-xs w-fit">
                  M (Adult)
                </label>
              </div>
              <div className="flex flex-col w-[10%] max-md:w-[20%] items-center">
                <input
                  name="num-LChild"
                  className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-blue-100 rounded-md w-full"
                  required
                  type="number"
                  min="0"
                  defaultValue="0"
                  onChange={(e) => setLChild(Number(e.target.value))}
                ></input>
                <label htmlFor="num-LChild" className="pb-[1vw] text-xs w-fit">
                  L (Child)
                </label>
              </div>
              <div className="flex flex-col w-[10%] max-md:w-[20%] items-center">
                <input
                  name="num-LAdult"
                  className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-blue-100 rounded-md w-full"
                  required
                  type="number"
                  min="0"
                  defaultValue="0"
                  onChange={(e) => setLAdult(Number(e.target.value))}
                ></input>
                <label htmlFor="num-LAdult" className="pb-[1vw] text-xs w-fit">
                  L (Adult)
                </label>
              </div>
              <div className="flex flex-col w-[10%] max-md:w-[20%] items-center">
                <input
                  name="num-XLChild"
                  className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-blue-100 rounded-md w-full"
                  required
                  type="number"
                  min="0"
                  defaultValue="0"
                  onChange={(e) => setXLChild(Number(e.target.value))}
                ></input>
                <label htmlFor="num-XLChild" className="pb-[1vw] text-xs w-fit">
                  XL (Child)
                </label>
              </div>
              <div className="flex flex-col w-[10%] max-md:w-[20%] items-center">
                <input
                  name="num-XLAdult"
                  className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-blue-100 rounded-md w-full"
                  required
                  type="number"
                  min="0"
                  defaultValue="0"
                  onChange={(e) => setXLAdult(Number(e.target.value))}
                ></input>
                <label htmlFor="num-XLAdult" className="pb-[1vw] text-xs w-fit">
                  XL (Adult)
                </label>
              </div>
              <div className="flex flex-col w-[10%] max-md:w-[20%] items-center">
                <input
                  name="num-XXLChild"
                  className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-blue-100 rounded-md w-full"
                  required
                  type="number"
                  min="0"
                  defaultValue="0"
                  onChange={(e) => setXXLChild(Number(e.target.value))}
                ></input>
                <label
                  htmlFor="num-XXLChild"
                  className="pb-[1vw] text-xs w-fit"
                >
                  XXL (Child)
                </label>
              </div>
              <div className="flex flex-col w-[10%] max-md:w-[20%] items-center">
                <input
                  name="num-XXLAdult"
                  className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-blue-100 rounded-md w-full"
                  required
                  type="number"
                  min="0"
                  defaultValue="0"
                  onChange={(e) => setXXLAdult(Number(e.target.value))}
                ></input>
                <label
                  htmlFor="num-XXLAdult"
                  className="pb-[1vw] text-xs w-fit"
                >
                  XXL (Adult)
                </label>
              </div>
              <div className="flex flex-col w-[10%] max-md:w-[20%] items-center">
                <input
                  name="num-XXXLChild"
                  className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-blue-100 rounded-md w-full"
                  required
                  type="number"
                  min="0"
                  defaultValue="0"
                  onChange={(e) => setXXXLChild(Number(e.target.value))}
                ></input>
                <label
                  htmlFor="num-XXXLChild"
                  className="pb-[1vw] text-xs w-fit"
                >
                  XXXL (Child)
                </label>
              </div>
              <div className="flex flex-col w-[10%] max-md:w-[20%] items-center">
                <input
                  name="num-XXXLAdult"
                  className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-blue-100 rounded-md w-full"
                  required
                  type="number"
                  min="0"
                  defaultValue="0"
                  onChange={(e) => setXXXLAdult(Number(e.target.value))}
                ></input>
                <label
                  htmlFor="num-XXXLAdult"
                  className="pb-[1vw] text-xs w-fit"
                >
                  XXXL (Adult)
                </label>
              </div>
              <div className="flex flex-col w-[10%] max-md:w-[20%] items-center">
                <input
                  name="num-XXXXLAdult"
                  className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-blue-100 rounded-md w-full"
                  required
                  type="number"
                  min="0"
                  defaultValue="0"
                  onChange={(e) => setXXXXLAdult(Number(e.target.value))}
                ></input>
                <label
                  htmlFor="num-XXXXLAdult"
                  className="pb-[1vw] text-xs w-fit"
                >
                  XXXXL (Adult)
                </label>
              </div>
              <div className="flex flex-col w-[10%] max-md:w-[20%] items-center">
                <input
                  name="num-XXXXXLAdult"
                  className="p-2 text-sm outline-none border-b-2 border-blue-400 bg-blue-100 rounded-md w-full"
                  required
                  type="number"
                  min="0"
                  defaultValue="0"
                  onChange={(e) => setXXXXXLAdult(Number(e.target.value))}
                ></input>
                <label
                  htmlFor="num-XXXXXLAdult"
                  className="pb-[1vw] text-xs w-fit"
                >
                  XXXXXL (Adult)
                </label>
              </div>
            </div>
            <a
              href="http://canadaspellingbee.com/speller"
              target="_blank"
              className="text-blue-500 hover:underline"
            >
              Click for Information (Including Sizing Chart)
            </a>

            <div>
              Total Shirts in Cart:{" "}
              {XXXXXLAdult +
                XXXXLAdult +
                XXXLChild +
                XXLChild +
                XLChild +
                LChild +
                MChild +
                XXXLAdult +
                XXLAdult +
                XLAdult +
                LAdult +
                MAdult +
                SAdult}
            </div>
            <div>
              Total Cost of Shirts (One Free Shirt):{" "}
              <b>
                $
                {XXXXXLAdult +
                  XXXXLAdult +
                  XXXLChild +
                  XXLChild +
                  XLChild +
                  LChild +
                  MChild +
                  XXXLAdult +
                  XXLAdult +
                  XLAdult +
                  LAdult +
                  MAdult +
                  SAdult >
                0
                  ? 25 *
                    (XXXXXLAdult +
                      XXXXLAdult +
                      XXXLChild +
                      XXLChild +
                      XLChild +
                      LChild +
                      MChild +
                      XXXLAdult +
                      XXLAdult +
                      XLAdult +
                      LAdult +
                      MAdult +
                      SAdult -
                      1)
                  : 0}{" "}
                CAD
              </b>
            </div>
          </div>

          <h1 className="text-xl font-semibold">Summer Camp Registration</h1>
          <div className="flex gap-4 flex-wrap pb-12">
            <div className="flex items-start gap-1 pb-[1vw] flex-col">
              <label htmlFor="date-selection" className="text-sm">
                Would you like to register for the 2024 CSSB Super Camp? (July
                17th - July 19th, 2024)
              </label>
              <div className="flex gap-2">
                <input
                  type="radio"
                  id="yes"
                  name="summer-camp"
                  required
                ></input>
                <label>Yes</label>
              </div>
              <div className="flex gap-2">
                <input type="radio" id="no" name="summer-camp" required></input>
                <label>No</label>
              </div>
              <div>Early Bird Fee: $299 CAD (until May 12 at 11:59PM EST)</div>
              <div>Regular Fee: $399 CAD</div>
              <a
                href="http://canadaspellingbee.com/speller"
                target="_blank"
                className="text-blue-500 hover:underline"
              >
                Click Here for More Information
              </a>
            </div>
          </div>
          <div className="text-center flex flex-col gap-3 max-sm:block max-lg:w-[100%]">
            <p className="bg-slate-300 m-3 p-2 max-lg:m-1 rounded-md max-lg:text-xs">
              We grant permission to Canada Super Spelling Bee to photograph,
              video, and capture images of my child at spelling bee
              competitions. We grant Canada Super Spelling Bee the right to
              reproduce, use, exhibit, display, broadcast, and distribute these
              images in any media to promote and publicize its activities. More
              information on the{" "}
              <a
                href="/consent-form"
                className="text-blue-400 underline"
                target="_blank"
              >
                Media Release and Consent Form
              </a>
              .
            </p>
            <p className="text-xs">
              If you have any questions regarding the use of photos, please
              contact us at info@canadaspellingbee.com
            </p>
            <p className="text-lg pt-4">
              By typing your digital signature (full legal name) below, you are
              acknowledging and agreeing to the consent form and above clauses.
              You are also confirming that the information above is accurate.
            </p>
            <div className="flex max-sm:flex-col">
              <div className="flex flex-col w-full m-2">
                <input
                  name="parent-signature"
                  className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                  required
                  onChange={(e) => setParentSignature(e.target.value)}
                ></input>
                <label htmlFor="parent-signature" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Parent
                  Signature
                </label>
              </div>
              <div className="flex flex-col w-full m-2">
                <input
                  name="date"
                  type="date"
                  className="p-2 text-sm outline-none border-b-2 focus:bg-green-100/50 border-green-700 bg-green-100/50 rounded-md"
                  required
                  onChange={(e) => setDate(e.target.value)}
                ></input>
                <label htmlFor="date" className="pb-[1vw] text-xs">
                  <span className="text-red-500 font-semibold">*</span>Date
                </label>
              </div>
            </div>
          </div>
          <div>Note: You cannot edit this form once you submit!</div>
          <button
            type="submit"
            className="p-2 text-lg bg-green-300 rounded-md px-5 mx-auto hover:bg-green-500"
          >
            Submit
          </button>
        </form>
      </div>
    </main>
  );
}
