import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useEffect, useState } from "react";

export default function UserDashboard() {
  const { user } = useAuthContext();
  const [toDo, setToDo] = useState([]);

  useEffect(() => {
    if (!user) {
      return;
    }

    let tempToDo = [];

    if (!user.compForm) {
      tempToDo = [
        ...tempToDo,
        "Fill out the National Competition Registration Form",
      ];
    }

    if (!user.campForm) {
      tempToDo = [...tempToDo, "Fill out the Extra Information Form"];
    }

    if (user.campForm && !user.payment) {
      tempToDo = [...tempToDo, "Pay for Camp and National Bee Accommodations"];
    }

    setToDo(tempToDo);
  }, []);

  return (
    <div>
      <h1 className="text-4xl text-center font-semibold max-sm:text-lg">
        Welcome back, {user.username}!
      </h1>
      <h2 className="text-lg text-center font-semibold p-4 my-8  bg-green-300 w-[30%] mx-auto rounded-md shadow-sm max-md:text-sm max-md:w-[75%]">
        You are now logged in to your 2024 National Bee portal!
      </h2>
      {toDo.length !== 0 ? (
        <h2 className="text-lg text-center font-semibold p-4 my-8  bg-green-300 w-[30%] mx-auto rounded-md shadow-sm max-md:w-[75%]">
          You have the following task(s) to complete:
          <ul>
            {toDo &&
              toDo.map((task) => (
                <li className="text-sm text-slate-700" key={task}>
                  - {task}
                </li>
              ))}
          </ul>
        </h2>
      ) : (
        <h2 className="text-lg text-center font-semibold p-4 my-8  bg-green-300 w-[30%] mx-auto rounded-md shadow-sm max-md:w-[75%]">
          You have no tasks for now!<br></br>
          <ul>
            <li className="text-sm text-slate-700">
              - You have completed the National Competition Registration Form
            </li>
            <li className="text-sm text-slate-700">
              - You have completed the Extra Information Form
            </li>
            <li className="text-sm text-slate-700">
              - You have paid for camp and national bee accommodations
            </li>
          </ul>
        </h2>
      )}
      <div className="flex justify-center gap-12 max-sm:gap-6">
        <Link to="/registration">
          {user.compForm ? (
            <button className="w-[20vw] text-[2em] m-4 p-5 bg-slate-700 rounded-md text-slate-400 max-lg:text-lg max-lg:w-[30vw]  max-sm:text-xs max-sm:p-2 hover:bg-slate-600">
              Registration Form is Complete<br></br>(Click to review
              information)
            </button>
          ) : (
            <button className="w-[20vw] text-[1.5em] m-4 p-5 bg-orange-400 rounded-md text-slate-100 hover:bg-orange-600 transition-colors active:bg-orange-800 max-lg:text-lg max-lg:w-[30vw] max-sm:text-xs max-sm:p-2">
              Fill out National Competition Registration Form
            </button>
          )}
        </Link>
        <Link to="/camp">
          {user.campForm ? (
            <button className="w-[20vw] text-[2em] m-4 p-5 bg-slate-700 rounded-md text-slate-400 max-lg:text-lg max-lg:w-[30vw]  max-sm:text-xs max-sm:p-2 hover:bg-slate-600">
              Extra Information Form is Complete<br></br>(Click to review)
            </button>
          ) : (
            <button className="w-[20vw] text-[1.5em] m-4 p-5 bg-orange-400 rounded-md text-slate-100 hover:bg-orange-600 transition-colors active:bg-orange-800 max-lg:text-lg max-lg:w-[30vw] max-sm:text-xs max-sm:p-2">
              Fill out Extra Information Form
            </button>
          )}
        </Link>
      </div>
      <div className="flex justify-center">
        {user.campForm &&
          (user.payment ? (
            <button
              className="w-[20vw] text-[1.5em] m-4 p-5 bg-green-700 rounded-md text-green-400 max-lg:text-lg max-lg:w-[30vw]  max-sm:text-xs max-sm:p-2"
              disabled
            >
              Payment is Complete
            </button>
          ) : (
            <Link to="/payment">
              <button className="w-[20vw] text-[1.5em] m-4 p-5 bg-orange-400 rounded-md text-slate-100 hover:bg-orange-600 transition-colors active:bg-orange-800 max-lg:text-lg max-lg:w-[30vw] max-sm:text-xs max-sm:p-1 max-sm:w-max">
                Pay for Camp and National Bee Accommodations
              </button>
            </Link>
          ))}
      </div>
    </div>
  );
}
