import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

export default function Navbar() {
  const { user } = useAuthContext();
  const { logout } = useLogout();
  const handleLogout = () => {
    logout();
  };

  return (
    <div>
      <header className="flex justify-between p-4 items-center bg-white mb-6 max-sm:flex max-sm:flex-col">
        <Link to="/" className="flex items-center gap-4">
          <img className="w-[50px] " src="logo.png"></img>

          <h1 className="text-3xl font-bold text-orange-300 hover:text-orange-500 max-md:text-lg max-sm:text-sm">
            Canada Super Spelling Bee
          </h1>
        </Link>
        <nav>
          <div>
            {user && (
              <div className="flex justify-center items-center">
                <span className="px-4 font-semibold">{user.username}</span>
                <button
                  onClick={handleLogout}
                  className="p-3 bg-orange-400 text-white rounded-md hover:bg-orange-600 transition-colors max-md:text-sm"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </nav>
      </header>
    </div>
  );
}
